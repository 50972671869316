import { useExternalScript } from "hooks/useExternalScript";
import { lowCodeWidgets, lowCodeUrls } from "../constants";
import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { camelToKebabCase, kebabToCamelCase } from "utils/string";

export const LowCodeWidget = ({ publisherId, offerId, env }) => {
  useExternalScript(lowCodeUrls[env], env);
  const { widgetName } = useParams();
  const widgetCamelCase = kebabToCamelCase(widgetName);

  const widgetExists = lowCodeWidgets.includes(widgetCamelCase);

  if (!widgetExists) {
    return (
      <div>
        <h2>Widget not found</h2>
        <p>The widget '{widgetName}' does not exist.</p>
        <WidgetNavigation />
      </div>
    );
  }

  return (
    <>
      <WidgetNavigation currentWidget={widgetName} />
      <h2 style={{ marginTop: '16px' }}>{widgetCamelCase} widget:</h2>
      <div
        data-cleeng-widget={widgetCamelCase}
        data-cleeng-publisher-id={publisherId}
        data-cleeng-offer-id={offerId}
        style={{ width: "80vw", height: "100vh" }}
      />
    </>
  );
};

const WidgetNavigation = ({ currentWidget }) => {
  return (
    <div>
      <h3 style={{ marginBottom: "16px" }}>Available Widgets:</h3>
      <ul
        style={{
          display: "flex",
          flexWrap: "wrap",
          listStyle: "none",
          gap: "10px",
        }}
      >
        {lowCodeWidgets.map((widget) => {
          const widgetKebab = camelToKebabCase(widget);
          return (
            <li
              key={widget}
              style={{
                padding: "5px 10px",
                backgroundColor:
                  currentWidget === widgetKebab ? "#e0e0e0" : "transparent",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            >
              <Link to={`/low-code-widgets/${widgetKebab}`}>{widget}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const LowCodeWidgets = ({ publisherId, offerId, env }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/low-code-widgets/purchase");
  }, [navigate]);

  return (
    <div>
      <h2>Loading...</h2>
    </div>
  );
};
