import { useEffect } from "react";

export const useExternalScript = (url, env) => {
  useEffect(() => {
    if (!url) {
      return;
    }

    const existingScript = document.querySelector(`script[src="${url}"]`);
    if (existingScript) {
      return;
    }

    const script = document.createElement("script");
    const isLocalhost =
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1";

    script.src = isLocalhost ? `/api/proxy-script?url=${encodeURIComponent(url)}` : url;
    script.type = "module";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [env, url]);
};
