export const lowCodeWidgets = [
    'register',
    'login',
    'purchase',
    'checkout',
    'subscriptions',
    'capture',
    'myAccount',
    'transactionsList',
    'subscriptionSwitches',
    'redeemGift',
    'passwordReset',
    'planDetails',
    'paymentInfo',
    'updateProfile',
    'checkoutConsents',
    'thankYou',
];

export const lowCodeUrls = {
    dev: 'https://widgets.dev.stardustlab.com/cleeng.js',
    staging: 'https://widgets.staging.stardustlab.com/cleeng.js',
    sandbox: 'https://widgets.sandbox.cleeng.com/cleeng.js',
    production: 'https://widgets.cleeng.com/cleeng.js',
};